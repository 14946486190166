import { COMPRAR, COMPRAR_ERROR, COMPRAR_EXITO, GUARDAR_DATOS_FACTURACION, GUARDAR_DATOS_FACTURACION_EXITO } from '../types';
import clienteAxios from '../config/axios';

export function compraAction(productos, direccion) {

	return async (dispatch) => {
		dispatch(comprar(true));
		try {
			dispatch(comprarExito(productos));
			
		} catch (error) {
			console.log(error);
			dispatch(comprarError(true));
		}
	};
}

const comprar = (loading) => ({
	type: COMPRAR,
	payload: loading,
});

const comprarExito = (productos) => ({
	type: COMPRAR_EXITO,
	payload: productos,
});

const comprarError = (error) => ({
	type: COMPRAR_ERROR,
	payload: error,
});

export function guardarDatosFacturacionAction(datosFacturacion) {
	return async (dispatch) => {
		dispatch(guardarDatosFacturacion(true));

		try {
			dispatch(guardarDatosFacturacionExito(datosFacturacion));
		} catch (error) {
			console.log(error);
		}
	};
}

const guardarDatosFacturacion = () => ({
	type: GUARDAR_DATOS_FACTURACION,
	
});

const guardarDatosFacturacionExito = (datosFacturacion) => ({
	type: GUARDAR_DATOS_FACTURACION_EXITO,
	payload: datosFacturacion,
});
