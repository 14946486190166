import React, { useState, useEffect } from 'react';
import TarjetasArticulos from '../components/Home.jsx/TarjetasArticulos';
import useHomeStyles from './hooks/useHomeStyles';
import TarjetaTrabajador from '../components/Home.jsx/TarjetaTrabajador';
import Modal from 'react-awesome-modal';
import { Parallax } from 'react-parallax';
import '../Css/Home.css';
import modalImg from '../assets/Glovo.png'
import styled from '@emotion/styled';

const DivModal = styled.div`
	display:flex;
	justify-content:center;
`

const ImagenGlovo = styled.img`
	width:200px;

	@media(min-width: 600px){
		width: 250px;
	}
`

const TituloModal = styled.h1`
	font-size: 1.5rem;

	@media(min-width:1000px){
		font-size:2rem;
	}
	
`


const Home = () => {
	const {Titulo, Subtitulo} = useHomeStyles();
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(()=>{
		setTimeout(()=>{
			setModalVisible(true)
		},500)
	},[])


	const closeModal = ()=>{
		setModalVisible(false)
	}

	return (
		<>
			<div className="row">
				<Parallax bgImage='https://i.ibb.co/f1swSC9/body-background2.webp' strength={300} className="col-12 p-0 imagen-home">
					<div className="row justify-content-center align-items-center home-background">
						<div className="col-8 text-center text-white  ">
							<Titulo><span>Bienvenido/a a Zas! Cake</span></Titulo>
							<Subtitulo>Pastelería creativa a medida</Subtitulo>
						</div>
					</div>
				</Parallax>

				<TarjetasArticulos></TarjetasArticulos>

				<Modal visible={modalVisible} id="hola"  effect="fadeInUp" onClickAway={()=> closeModal()}>
					<div className="row justify-content-center p-4">
						<div className="col-auto">
							<TituloModal>Envíos a <b>domicílio</b></TituloModal>	
						</div>
						<hr className="col-10"></hr>
						<DivModal className="col-8">
							<ImagenGlovo  alt="GlovoApp" src={modalImg}></ImagenGlovo>
						</DivModal>
					</div>	
				</Modal>

				<Parallax bgImage="https://i.ibb.co/cyMRZxg/background-trabajador-home.webp" strength={300} className="second-container">
					<div className="row justify-content-center home-background align-items-center">
						<TarjetaTrabajador></TarjetaTrabajador>
					</div>
				</Parallax>
			</div>
		</>
	);
};

export default Home;
