import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/Checkout/CheckoutForm';
import { useHistory } from 'react-router-dom';
// import './Checkout.css';
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.

const promise = loadStripe(
	'pk_test_51GtyGDCgn9UssWNywQiQAqtHafwbIq1cOfIvQo64EUi8XUL7s8GO0PD0OXJUga32JdXnJU147BvZKYLpQX88rFdr00CHSUV0c4'
);



const Checkout = () => {

	const history = useHistory()
	useEffect(() => {
		let compra = JSON.parse(localStorage.getItem('compra'));
		if (!compra) history.push('/');
	}, []);
	return (
		<Elements stripe={promise}>
			<div className="row justify-content-center m-4">
				<CheckoutForm />
			</div>
		</Elements>
	);
};

export default Checkout;
