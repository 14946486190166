import React ,{useState} from 'react';
import Especialidades from '../components/Especialidades/Especialidades'
const Cupcakes = () => {
    const [tartas, saveTartas] = useState([
        {
            texto:"Vainilla",
            url:'https://bakerish.com/wp-content/uploads/2019/03/VANILLA-CUPCAKES.jpg',
            alt:"Vainilla"   
        },
        {
            texto:"Fresa",
            url:'https://celebratingsweets.com/wp-content/uploads/2015/02/Chocolate-Strawberry-Cupcakes-1-2.jpg',
            alt:"Fresa"
        },
        {
            texto:"Chocolate",
            url:'https://thebusybaker.ca/wp-content/uploads/2019/02/birthday-cake-cupcakes-with-chocolate-frosting-fb-ig-3.jpg',
            alt:"Chocolate"
        },
        {
            texto:"Oreo",
            url:'https://www.tasteofhome.com/wp-content/uploads/2019/12/Oreo-Cupcakes-with-Cookies-and-Cream-Frosting_EXPS_FT19_247265_F_1203_1.jpg',
            alt:"Oreo"
        }
    ])
    return (  
        <Especialidades productos={tartas} titulo="Carta de Cupcakes"></Especialidades>
    );
}
 
export default Cupcakes;