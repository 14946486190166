import { GUARDAR_PRODUCTO, GUARDAR_PRECIO_TOTAL } from '../types';
const initialState = {
	carrito: [],
	loading: null,
	error: null,
	precioTotal: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GUARDAR_PRODUCTO:
			let existe = false;
			state.carrito.map((producto) => {
				if (producto._id === action.payload._id) {
					producto.cantidad = action.payload.cantidad;
					existe = true;
					return;
				}
			});
			if (!existe) {
				state.carrito.push(action.payload);
			}
			return {
				...state,
			};

		case GUARDAR_PRECIO_TOTAL:
			return { ...state, precioTotal: action.payload };
		default:
			return state;
	}
}
