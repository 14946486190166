import React, {useState} from 'react'
import styled from '@emotion/styled'
import IconoSocialMedia from './IconoSocialMedia.jsx';
import Instagram from '../../assets/socialMedia/001-instagram.png'
import Facebook from '../../assets/socialMedia/facebook.png'
import Gmail from '../../assets/socialMedia/gmail.png'
import Whatsapp from '../../assets/socialMedia/whatsapp.png'

const ContenedorIconos = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`

const Titulo = styled.h1`
	font-family: 'Times New Roman';
	font-size: 1rem;
	font-weight: bold;
	position: relative;
    overflow: hidden;
    
    span {
        display: inline-block;
        vertical-align: baseline;
        zoom: 1;
        *display: inline;
        *vertical-align: auto;
        position: relative;
        padding: 0 20px;

        &:before, &:after {
            content: '';
            display: block;
            width: 20px;
            position: absolute;
            top: 0.67em;
            border-top: 1px solid white;
        }

        &:before { right: 100%; }
        &:after { left: 100%; }
	}

	@media(min-width:500px){
		font-size:2rem;
	}
	@media(min-width:800px){
		font-size:2.5rem;
	}
	@media(min-width:1000px){
		font-size:3rem;
	}
`;


const SocialMedia = () => {
  const [iconos, saveIconos] = useState([
    {
      url:'https://www.instagram.com/zas_cake/?hl=en',
      src:Instagram,
      alt:"Instagram"
    },
    {
      url:'https://es-es.facebook.com/zascake',
      src:Facebook,
      alt:"Facebook"
    },
    {
      url:'mailto:zascake@gmail.com',
      src:Gmail,
      alt:"Gmail"
    },
    {
      url:'https://api.whatsapp.com/send?phone=+34664604165&text=Hola, me interesa ...',
      src:Whatsapp,
      alt:"Whatsapp"
    }
  ])
  return (
   <> 
      <div>
          <div>
            <Titulo><span>Zas! Cake</span></Titulo>
          </div>
          <ContenedorIconos>
            {
              iconos.map((icono)=>
              <IconoSocialMedia icono={icono} key={icono.alt} ></IconoSocialMedia>
              )
            }
          </ContenedorIconos>
      </div>
   </> 
  )
}

export default SocialMedia
