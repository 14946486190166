import React , {useState} from 'react';
import Especialidades from '../components/Especialidades/Especialidades';

const Cupcakes = () => {
    const [tartas, saveTartas] = useState([
        {
            texto:"Red velvet",
            url:"https://i.ibb.co/ScM9tJz/red-Velvet.jpg",
            alt:"Red velvet"   
        },
        {
            texto:"Cheese cake",
            url:"https://i.ibb.co/FwRZWBX/cheese-Cake.jpg",
            alt:"Cheese cake"
        },
        {
            texto:"Apple Cake",
            url:"https://i.ibb.co/WnNmRg0/applecake.webp",
            alt:"Cheese cake"
        },
        {
            texto:"Bound de Choc",
            url:"https://i.ibb.co/qx2n6NX/chocolate-Cake.jpg",
            alt:"Bound de chocolate"
        }
    ])
    return (

       
        <Especialidades productos={tartas} titulo="Carta de Tartas"></Especialidades>


      );

}
 
export default Cupcakes;