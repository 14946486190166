import React from 'react'
import useLocalStyles from './hooks/useLocalStyles';
import '../Css/Local.css';
import { Parallax } from 'react-parallax';

const Local = () => {
    const {TituloHeader, CajaDescripcionLocal, TituloContenedor} = useLocalStyles();

    return (

        <div className="row p-3 justify-content-center">
            <Parallax className="col-12 contenedor-parallax" bgImage="https://i.ibb.co/nnfRGVj/background-local.webp" strength={300} >
                <div className="row justify-content-center align-items-center header-local">
                    <div className="col-8 text-center text-white">
                        <TituloHeader> <span>Nuestros talleres</span></TituloHeader>
                    </div>
                </div>
        
            </Parallax>

            <div className="col-12 local-background p-2">
                <div className="row justify-content-center mt-3">
                    <div className="col-xs-10 col-md-6 col-lg-8 order-12 order-md-1">
                        <CajaDescripcionLocal>
                            <TituloContenedor>Los talleres más divertidos del sábado</TituloContenedor>
                            <hr></hr>
                            <p>Nuestros <b>cursos de repostería en Barcelona</b> se realizan los sábados para aprovechar un día libre sin escuela.</p>
                            <p>Contamos con una amplia experiencia en el trato con niños. Los grupos son reducidos para asegurar un buen ambiente.</p>
                            <p>No dudes en informarte de nuestros próximos <b>talleres para niños</b> y actividades para <b>cumpleaños infantiles</b> que ofrecemos.</p>
                            <p>Además, tenemos en Barcelona una sala para fiestas o eventos <i>team building</i> que puedes alquilar cuando necesites.</p>
                        </CajaDescripcionLocal>
                    </div>
                    <div className="col-8 col-md-6 col-lg-4 order-1 order-md-12">  
                        <img src="https://i.ibb.co/4dW5pPT/taller.png" className="w-100" alt="imagen taller"></img>
                    </div>

                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-8 col-md-6 col-lg-4 ">
                        <img src="https://i.ibb.co/Yt0RXCC/local.png" className="w-100" alt="imagen local"></img>
                    </div>
                    <div className="col-xs-10 col-md-6 col-lg-8 ">
                        <CajaDescripcionLocal>
                            <TituloContenedor>Diferentes actividades sobre repostería</TituloContenedor>
                            <hr></hr>
                            <p>Ofrecemos <b>actividades para niños</b> muy variadas dentro del ámbito de los cursos de respostería. Los más pequeños aprenderán el arte
                            del <b>modelaje</b> de figuras en fondant, creando sus propias obras. Tenemos talleres de <i>respostería general</i>, y también talleres de
                            decoración de <b>cupcakes y decoración de galletas.</b></p>
                            <p>En cada taller intentamos que el niño se lo pase en grande y que nutra su creatividad. A su vez, <b>crear cosas con sus propias manos</b>
                            , ayuda a ganar confianza y a relajarse. 
                            <br></br>
                            <br></br>
                            Nosotros proporcionamos los ingredientes y materiales para que no tengas que preocuparte de nada.</p>
                        </CajaDescripcionLocal>
                    </div>
                </div>
            </div>

        </div>





    );
}

export default Local;