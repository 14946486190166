import React, { Fragment, useState } from 'react';
import { obtenerProductosAction, buscarProductosAction } from '../../actions/productosActions';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

const ElementoLista = styled.li`
	&:hover {
		box-shadow: 0px 1px 2px #bbb, 1px 2px 3px #bbb, 2px 3px 4px #bbb;
		z-index: 10;
		cursor: pointer;
	}

	&:active {
		box-shadow: 0px 1px 2px #bbb inset, 1px 2px 3px #bbb inset, 2px 3px 4px #bbb inset;
	}

	font-size: 20px;
`;

const Sidebar = () => {
	const dispatch = useDispatch();
	const handleClick = (e) => {
		dispatch(obtenerProductosAction(e));
	};
	const [busqueda, guardarBusqueda] = useState('');

	const handleChange = (e) => {
		guardarBusqueda(e.target.value);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (busqueda.trim() === '') {
			return;
		}

		dispatch(buscarProductosAction(busqueda.trim()));

		guardarBusqueda('');
	};
	return (
		<Fragment>
			<div className="col-auto bg-light mt-3 mb-3 sidebar-box p-2 ">
				<h3>Nuestros Productos </h3>
				<div className="col-12">
					<form onSubmit={handleSubmit}>
						<div className="form-group row justify-content-between">
							<input
								className="form-control col-8 col-lg-12"
								name="busqueda"
								value={busqueda}
								onChange={handleChange}
								placeholder="Que quieres buscar?"
							></input>
							<button className="btn btn-success col-3 col-lg-12 mt-lg-1 btn-sm">Buscar</button>
						</div>
					</form>
				</div>
				
				<ul className="list-group">
					<ElementoLista
						className="list-group-item"
						onClick={() => handleClick('ALIMENTACION|PASTAS DE AZUCAR')}
					>
						<a href="#">Fondant</a>
					</ElementoLista>
					<ElementoLista
						className="list-group-item"
						onClick={() => handleClick('ALIMENTACION|AROMAS Y CONCENTRADOS|LIQUIDOS')}
					>
						<a href="#">Colorantes</a>
					</ElementoLista>
					<ElementoLista className="list-group-item" onClick={() => handleClick('MOLDES')}>
						<a href="#">Moldes</a>
					</ElementoLista>
				</ul>
			</div>
		</Fragment>
	);
};

export default Sidebar;
