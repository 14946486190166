import React, { useState, useEffect } from 'react';
import ListaProductosCarrito from '../components/Carrito/ListaProductosCarrito';
import CarritoSidebar from '../components/Carrito/CarritoSidebar';
import styled from '@emotion/styled'
import NingunProductoSeleccionado from '../assets/Carrito/NingunProductoSeleccionado.jpg'

const CarritoVacioBox = styled.div`

	min-height:600px;

`

const TituloCarritoVacio= styled.h2`
	font-size:15px;
	font-family: 'Times New Roman', Times, serif;
    text-align: center;
    border-radius: 5px;
	min-height:40px;
	line-height:40px;
	@media(min-width:900px){
		font-size:20px;
	}

`
const Carrito = () => {


	const [productosCarrito, guardarProductosCarrito] = useState([]);
	const [precioTotal, guardarPrecioTotal] = useState(0);

	useEffect(() => {
		localStorage.setItem('compra', false);
		recargarProductos();
	}, []);
	useEffect(() => {
		if (productosCarrito) recalcularPrecioTotal();
	}, [productosCarrito]);

	const recalcularPrecioTotal = () => {
		guardarPrecioTotal(0);
		productosCarrito.map((producto) =>
			guardarPrecioTotal((precioTotal) => precioTotal + producto.cantidad * producto.precio_bruto)
		);
	};

	const recargarProductos = () => {
		guardarProductosCarrito(JSON.parse(localStorage.getItem('carrito')));
		if (!productosCarrito) {
			localStorage.setItem('carrito', '[]');
			// guardarProductosCarrito([]);
		}
	};

	if (productosCarrito) {
		return (
			<>
				<h2>Gracias por confiar en nosotros</h2>

				<div className="row mt-3 mb-3">
					<div className="col-12 col-md-9">
						<ListaProductosCarrito
							productosCarrito={productosCarrito}
							recargarProductos={recargarProductos}
						></ListaProductosCarrito>
					</div>
					<div className="col-12 col-md-3 ">
						<CarritoSidebar
							precioTotal={precioTotal}
							recargarProductos={recargarProductos}
						></CarritoSidebar>
					</div>
				</div>
			</>
		);
	} else return (
		<CarritoVacioBox className="row justify-content-center">
			<div className="col-11 pt-3">
				<TituloCarritoVacio className="bg-dark text-light">Aún no ha seleccionado ningún producto</TituloCarritoVacio>
			</div>
			<div className="col-10 col-md-6 col-lg-4  ">
				<img className="w-100 rounded" src ={NingunProductoSeleccionado} alt="ningún producto seleccionado"></img>
			</div>
		</CarritoVacioBox>);
};

export default Carrito;
