import styled from '@emotion/styled'

const ContenedorImagen = styled.div`
    display:flex;
    justify-content:center;
`

const ImagenProducto= styled.img`
    width:200px;
    height:200px;
    object-fit:cover;
    @media(min-width:1600px){
        height:250px;
        width:250px;
    }

`
const TextoProducto= styled.h2`
    font-size:1.5rem;
`

const Container = styled.div`
    min-height:500px;
`
const TituloEspecialidad = styled.h1`
    text-align:center;
    font-size:1.5rem;
    &:before{
        border-top: 1px solid #a8a8a8;
        content:"";
        margin:0 auto;
        position:absolute;
        top:50%; left:0; right:0;bottom:0;
        width:70%;
        z-index:-1;
    }
    @media(min-width:500px){
        font-size:2rem;
        &:before{
            width:50%;
        }
    }
    @media(min-width:700px){
        &:before{
            width:40%;
        }
    }
    @media(min-width:1000px){
        font-size:2.5rem;
        &:before{
            width:30%;
        }
    }
    span{
        background:#fff;
        padding:0 15px;

    }
`
const ContenedorTitulo = styled.div`
    height:auto;

`
const Card = styled.div`
    height:100%;

`
const useEspecialidadStyles= ()=> {
    return{ContenedorImagen, ImagenProducto, TextoProducto, Container, TituloEspecialidad, ContenedorTitulo, Card};
}
 
export default useEspecialidadStyles;