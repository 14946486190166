import { useState } from 'react';
import ImagenPrueba from '../../../assets/Articles/pastelCrema.webp';
import Cupcake from '../../../assets/Articles/cupcake.webp';
import Galleta from '../../../assets/Articles/galletas.webp';
import styled from '@emotion/styled';
const TituloArticulos = styled.h1`
	font-size: 30px;
	@media (min-width: 900px) {
		font-size: 60px;
	}
`;
const TextoTitulo = styled.p`
	font-size: 14px;
	font-family: none;
	@media (min-width: 900px) {
		font-size: 20px;
	}
`;
const useArticulos= () => {
	const [tarjetas, guardarTarjetas] = useState([
		{
			titulo: 'Pasteles',
			texto: 'Contacta con nosotros para obtener información',
			imagen: ImagenPrueba,
		},
		{
			titulo: 'Cupcakes',
			texto: 'Cada día te sorprenderemos con nuevos sabores',
			imagen: Cupcake,
		},
		{
			titulo: 'Galletas',
			texto: 'Galletas de primera recién horneadas',
			imagen: Galleta,
		},
	]);

    return {TituloArticulos, TextoTitulo, tarjetas};
};

export default useArticulos;

