import React,{useState} from 'react';
import logo from '../../assets/Logo/logo.webp';
import { NavLink } from 'react-router-dom';
import "../../Css/Navbar.css"
const Navbar = () => {


	const [menuSeleccionado, saveMenuSeleccionado] = useState("");
	const [menuDesplegado, saveMenuDesplegado] = useState(false);

	const handleClickMenu= ()=>{
		saveMenuDesplegado(true);
	}
	const handleClick = (seleccion)=>{
		saveMenuDesplegado(false);
		saveMenuSeleccionado(seleccion);
	}

	return (

		<nav className="navbar navbar-expand-md navbar-light bg-light border-bottom w-100">
			<button
				className={`navbar-toggler ${menuDesplegado ? "" : "collapsed"}`}
				type="button"
				data-toggle="collapse"
				data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded={`${menuDesplegado ? "true":"false" }`}
				aria-label="Toggle navigation"
				onClick={()=>handleClickMenu()}
			>
				<span className="navbar-toggler-icon"></span>
			</button>

			<div className={`collapse navbar-collapse row ${menuDesplegado ? "show" : ""}`} id="navbarSupportedContent">
				<ul className="navbar-nav mr-auto col-12 col-md-5 justify-content-around">
					<li className="nav-item  ">
						{/* eslint-disable-next-line */}
						<NavLink className={`nav-link enlace-header ${menuSeleccionado === "home" ? "activo" : ""}`} onClick={()=>handleClick("home")} to='/'>
							Home
						</NavLink>
					</li>

					<li className="nav-item active">
						{/* eslint-disable-next-line */}
						<NavLink to="local" className={`nav-link enlace-header ${menuSeleccionado ==="local" ? "activo" : ""}`} exact onClick={()=>handleClick("local")} >
							Talleres y Local	
						</NavLink>
					</li>
				</ul>
				<div className="col-2 d-none d-md-flex justify-content-center contenedorLogoZasCake">
					<div className="col d-flex text-center">
						<NavLink to="/" onClick={()=>handleClick("home")}>
							<img src={logo} alt="logo" width="50%" height="50%"></img>
						</NavLink>
					</div>
				</div>
				<ul className="navbar-nav mr-auto col-12 col-md-5 justify-content-around">
					{/*	
						<li className="nav-item active ">
							<Link to="tienda" className="nav-link enlace-header" href="#">
								Tienda
							</Link>
						</li>
					*/}
					<li className="nav-item dropdown">
						<a className={`nav-link dropdown-toggle enlace-header ${menuSeleccionado ==="especialidades" ? "activo":""}`}   id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Especialidades
						</a>
						<div className="dropdown-menu" aria-labelledby="navbarDropdown">
							<NavLink className="dropdown-item" to="/pasteles" onClick={()=>handleClick("especialidades")}>
								Tartas	
							</NavLink>
							<NavLink className="dropdown-item" to="/Cupcakes" onClick={()=>handleClick("especialidades")}>
								CupCakes
							</NavLink>
							<NavLink className="dropdown-item" to="/galletas" onClick={()=>handleClick("especialidades")}>
								Galletas
							</NavLink>
						</div>
					</li>
					<li className="nav-item  ">
						{/* eslint-disable-next-line */}
						<NavLink className={`nav-link enlace-header ${menuSeleccionado ==="contacto" ? "activo" :""}`}   to="/contacto" exact onClick={()=>handleClick("contacto")} >
							Contacto
						</NavLink>
					</li>
	

				</ul>
			</div>
			<div className="col-3 d-md-none">
				<img src={logo} alt="logo" width="50%"></img>
			</div>
		</nav>
	);
};

export default Navbar;
