import React from 'react'
import useTrabajadorStyles from './hooks/useTrabajadorStyles'
const TarjetaTrabajador = () => {
  const {TextoTrabajador, ContenedorTrabajador, TituloTrabajador} = useTrabajadorStyles();
  return (
    <>
      <ContenedorTrabajador className='col-9 d-md-flex bg-light mt-5 mb-5 p-3'>
        <div className='col-12 col-md-6 col-lg-5 p-0'>
          <div className='imagen-trabajador '></div>
        </div>
        <div className='col-12 col-md-6 col-lg-7'>
          <TituloTrabajador>Somos artistas dedicados</TituloTrabajador>
          <TextoTrabajador>
            En Zas! Cake trabajamos con los mejores expertos en diseño y confección de pastelería. 
          </TextoTrabajador>
          <hr></hr>
          <TextoTrabajador>
            Valoramos el trabajo duro. 
            <br></br>
            Siempre estamos pendientes del beneficio del cliente, trabajando codo con codo con cada uno de ellos y 
            haciéndole partícipe del proceso de desarrollo creativo de su producto.
            <br></br>
            Ofrecemos total asistencia durante el confeccionamiento, escuchando todas las propuestas y adaptandonos a las necesidades del cliente.
          </TextoTrabajador>
        </div>
      </ContenedorTrabajador>
    </>
  )
}

export default TarjetaTrabajador
