import React from 'react';
import Especialidad from './Especialidad';
import useEspecialidadStyles from './hooks/useEspecialidadStyles.jsx';
import '../../Css/Especialidades.css'
const Especialidades = ({productos, titulo}) => {

    const {Container, TituloEspecialidad, ContenedorTitulo} = useEspecialidadStyles();
    return (  
        <Container className="col-12">
            <ContenedorTitulo className="row mt-5">
                <div className="col-12 text-center">
                    <TituloEspecialidad><span>{titulo}</span></TituloEspecialidad>
                </div>
            </ContenedorTitulo>
            <div className="row">
                {productos.map( (producto)=>
                    <Especialidad texto={producto.texto} url={producto.url} alt ={producto.alt} key={producto.texto} >
                    </Especialidad>)
                }
            </div>
        </Container>
    );
}
 
export default Especialidades;