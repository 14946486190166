import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compraAction } from '../../actions/compraActions';

const ContenedorSidebar = styled.div`
	border-radius: 5px;
	min-height: 150px;
`;

const CompraSidebar = ({ camposRellenos }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const datosFacturacion = useSelector((state) => state.compraReducer.datosFacturacion);
	const [precioTotal, guardarPrecioTotal] = useState(0);
	const handleClick = () => {

		if (camposRellenos) {
			const productos = JSON.parse(localStorage.getItem('carrito'));
			dispatch(compraAction(productos, datosFacturacion));
			console.log('campos rellenos');
			localStorage.setItem('compra', true);
			history.push('/checkout');
		}


	};
	useEffect(() => {
		guardarPrecioTotal(parseFloat(localStorage.getItem('precioTotal')));
	}, []);
	return (
		<ContenedorSidebar className="row bg-dark text-light justify-content-center pt-2 pb-2">
			<div className="col-12 text-center d-flex justify-content-center align-items-center">
				<p>Precio Final: {precioTotal.toFixed(2)}</p>
			</div>
			{precioTotal > 70 ? null : (
				<>
					<div className="col-12 text-center">
						<p>+9eur gastos envío</p>
					</div>
					<div className="col-12 text-center">
						<p>Precio Final: {(precioTotal + 9).toFixed(2)}</p>
					</div>
				</>
			)}
			<div className="col-12 d-flex justify-content-center align-items-center">
				<button className="btn btn-lg btn-warning text-center" onClick={handleClick}>
					Tramitar pedido
				</button>
			</div>
		</ContenedorSidebar>
	);
};

export default CompraSidebar;
