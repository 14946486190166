import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import "./Checkout.css";

const Precio = styled.p`
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;
export default function CheckoutForm() {
  const history = useHistory();
  const productos = JSON.parse(localStorage.getItem("carrito"));


  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [precioTotal, setPrecioTotal] = useState(0);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",

        fontFamily: "Arial, sans-serif",

        fontSmoothing: "antialiased",

        fontSize: "16px",

        "::placeholder": {
          color: "#32325d",
        },
      },

      invalid: {
        color: "#fa755a",

        iconColor: "#fa755a",
      },
    },
  };

  const crearIntentoPago = () => {
    window
      .fetch(`${process.env.REACT_APP_BACKEND_URL}api/compra`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(productos),
      })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        setPrecioTotal(data.amount);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (productos) {
      console.log(productos);
      // Create PaymentIntent as soon as the page loads
      crearIntentoPago();
    }
  }, []);

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    setProcessing(true);

    try {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        localStorage.removeItem("carrito");
      }
    } catch (error) {
      console.log(error);
      history.push("/");
    }
  };

  return (
    <>
      {succeeded ? (
        <>
          <div className="row justify-content-center">
            <p>
              Gracias por su compra, recibirá una copia de la factura en su
              correo
            </p>
            <div className="col-12 col-md-8 col-lg-5 col-xl-4">
              <button
                className="btn btn-success"
                onClick={() => history.push("/")}
              >
                Volver a Inicio
              </button>
            </div>
          </div>
        </>
      ) : (
        <form
          id="payment-form"
          className="bg-dark form-checkout col-12 col-md-8 col-lg-7 col-xl-5"
          onSubmit={handleSubmit}
        >
          <Precio>{precioTotal} eur</Precio>
          <div className="form-group">
            <label></label>
          </div>
          <CardElement
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />

          <button disabled={processing || disabled || succeeded} id="submit">
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay"
              )}
            </span>
          </button>

          {error && (
            <div className="card-error text-light" role="alert">
              {error}
            </div>
          )}
        </form>
      )}
    </>
  );
}
