import React, { Fragment, useState, useEffect } from 'react';
import ListaProductos from '../components/Productos/ListaProductos';
import Sidebar from '../components/tienda/Sidebar';
import styled from '@emotion/styled';
const Alerta = styled.div`
	position: fixed;
	top: 0px;
	z-index: 100;
`;
const Tienda = () => {
	const [alerta, guardarAlerta] = useState({
		mostrar: false,
		msg: '',
	});

	if (alerta.mostrar) {
		setTimeout(() => {
			guardarAlerta({
				mostrar: false,
				msg: '',
			});
		}, 1500);
	}
	useEffect(() => {
		localStorage.setItem('compra', false);
	}, []);
	return (
		<Fragment>
			<div className="row justify-content-center">
				{alerta.mostrar ? (
					<Alerta className="alert alert-success col-8 ">
						<p>{alerta.msg}</p>
					</Alerta>
				) : null}
				<div className="col-12 col-lg-2 text-center text-md-left">
					<Sidebar></Sidebar>
				</div>
				<div className="col p-1">
					<ListaProductos guardarAlerta={guardarAlerta}></ListaProductos>
				</div>
			</div>
		</Fragment>
	);
};

export default Tienda;
