
import {useState} from 'react';
import  clienteAxios from '../../../config/axios';
const Form = () => {

  const [correo, guardarCorreo] = useState({
    email: "",
    consulta: "",
    imagen: "",
  });

  const { email, consulta, imagen } = correo;

  const [alerta, guardarAlerta] = useState({
    evento: false,
    msg: "",
    categoria: "",
  });


  const enviarCorreo = () => {
    try {
      console.log(imagen);
      clienteAxios.post("api/correo", { email, consulta, imagen });
    } catch (error) {
      console.log(error);
    }
  };

  
  const handleChange = (e) => {
    if(e.target.name ==="imagen"){
      const data = new FormData();
      data.append('imagen', e.target.files[0]);
      console.log(e.target.files[0])
      console.log(data)
      guardarCorreo({
        ...correo,
        [e.target.name]:data
      })
    }
    else
      guardarCorreo({
        ...correo,
        [e.target.name]: e.target.value,
      });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (email.trim() === "" || consulta.trim() === "") {
      let mensaje = "";
      if (email.trim() === "") mensaje += "Correo obligatorio";
      if (consulta.trim() === "") mensaje += " Consulta obligatoria";
      guardarAlerta({
        evento: true,
        msg: mensaje,
        categoria: "danger",
      });

      setTimeout(function () {
        guardarAlerta({
          evento: false,
          msg: "",
        });
      }, 4000);

      return;
    } else {
      enviarCorreo();

      guardarAlerta({
        evento: true,
        msg: "Consulta enviada con éxito",
        categoria: "success",
      });

      setTimeout(function () {
        guardarAlerta({
          evento: false,
          msg: "",
        });
      }, 4000);
      guardarCorreo({
        email: "",
        consulta: "",
        imagen: "",
      });

     }

  };

  return {alerta, email, consulta, handleSubmit, handleChange}
};

export default Form;
