import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logInAction } from '../actions/authActions';
import { useHistory } from 'react-router-dom';
import FormularioLogin from '../components/ui/FormularioLogin';
const Login = () => {
	useEffect(() => {
		localStorage.setItem('compra', false);
	}, []);
	return <FormularioLogin ruta="/"></FormularioLogin>;
};

export default Login;
