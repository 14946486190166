export const OBTENER_PRODUCTOS = 'OBTENER_PRODUCTOS';
export const OBTENER_PRODUCTOS_EXITO = 'OBTENER_PRODUCTOS_EXITO';
export const OBTENER_PRODUCTOS_ERROR = 'OBTENER_PRODUCTOS_ERROR';

export const BUSCAR_PRODUCTOS = 'BUSCAR_PRODUCTOS';
export const BUSCAR_PRODUCTOS_EXITO = 'BUSCAR_PRODUCTOS_EXITO';
export const BUSCAR_PRODUCTOS_ERROR = 'BUSCAR_PRODUCTOS_ERROR';

export const GUARDAR_PRODUCTO = 'GUARDAR_PRODUCTO';
export const GUARDAR_PRECIO_TOTAL = 'GUARDAR_PRECIO_TOTAL';

export const COMPRAR = 'COMPRAR';
export const COMPRAR_ERROR = 'COMPRAR_ERROR';
export const COMPRAR_EXITO = 'COMPRAR_EXITO';

export const GUARDAR_DATOS_FACTURACION = 'GUARDAR_DATOS_FACTURACION';
export const GUARDAR_DATOS_FACTURACION_EXITO = 'GUARDAR_DATOS_FACTURACION_EXITO';
