import React from "react";
import useEnviarDatosFormulario from './hooks/useEnviarDatosFormulario'


const FormContacto = ({
}) => {

  const { alerta, email, consulta, imagen, handleSubmit, handleChange } = useEnviarDatosFormulario();
  return (
    <form onSubmit={handleSubmit} className="row">
      {alerta.evento ? (
        <div className={`alert alert-${alerta.categoria} col-12` } role="alert">
          {alerta.msg}
        </div>
      ) : null}
      <div className="form-group col-12">
        <label>Correo de contacto:</label>
        <input
          className="form-control"
          type="email"
          name="email"
          placeholder="Introduce tu mail"
          value={email}
          onChange={handleChange}
        ></input>
      </div>
      <div className="form-group col-12">
        <label>Que quieres consultar?</label>
        <textarea
          className="form-control"
          type="text"
          name="consulta"
          value={consulta}
          onChange={handleChange}
        ></textarea>
      </div>
      <button className="btn btn-success m-3 col-3">Enviar</button>
    </form>
  );
};

export default FormContacto;
