import React from 'react';
import styled from '@emotion/styled'

const Imagen = styled.img`
    width:40%;
`

const IconoSocialMedia = ({icono}) => {

    const {url, src, alt} = icono;

    return (
        <div className='icon'>
            <a href={url}>
            <Imagen src={src} alt={alt}></Imagen>
            </a>
        </div>
      );

}
 
export default IconoSocialMedia;