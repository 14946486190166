import React from 'react'
import SocialMedia from './SocialMedia'
import styled from '@emotion/styled'
import '../../Css/Footer.css'
const ContenedorFooter = styled.div`
 
`
const TituloContenedor = styled.h2`
  font-size:1.5rem;
`
const SubtituloContenedor = styled.p`
  font-size:1rem;
`
const ContenidoContenedor = styled.p`
  font-size:.8rem;
`
const Footer = () => {
  return (
      <ContenedorFooter className='footer bg-dark text-light text-center'>

        <div className="contenedorHorario">
          <div>
            <TituloContenedor>Horario</TituloContenedor>
          </div>
          <div className="contenedorHorario-subcontenedor">
            <div>
              <SubtituloContenedor>Martes a viernes:</SubtituloContenedor>
              <ContenidoContenedor>10:00 - 13:30</ContenidoContenedor>
              <ContenidoContenedor>17:00 - 19:00</ContenidoContenedor>
            </div>
            <div>
              <SubtituloContenedor >Sábados:</SubtituloContenedor>
              <ContenidoContenedor>10:00 - 14:00</ContenidoContenedor>
            </div>
          </div>
        </div>

        <div className="contenedorContacto">
          <div>
            <TituloContenedor>Contacto</TituloContenedor>
          </div>
          <div className="contenedorContacto-telefonos">
            <ContenidoContenedor>Fijo: 931 419 775 </ContenidoContenedor>
            <ContenidoContenedor>Movil: 664 604 165</ContenidoContenedor>
          </div>
        </div>

        <SocialMedia></SocialMedia>
        
      </ContenedorFooter>
  )
}

export default Footer
