import React from 'react';
import Tarjeta from './Tarjeta';
import useArticulos from './hooks/useArticulos'

const TarjetasArticulos = () => {

	const {TituloArticulos, TextoTitulo, tarjetas} = useArticulos();

	return (
		<div className="row mt-2">
			<div className="col-12 text-center text-dark justify-content-center d-flex ">
				<div className="col-12 col-md-8 ">
					<TituloArticulos>Nuestras especialidades!</TituloArticulos>
					<TextoTitulo>
						<hr></hr>
						En <b>Zas! Cake</b> trabajamos con productos de calidad, productos de cercanía.
						<br></br>
						Ven a probar nuestros deliciosos CupCakes o pide un par de galletas para llevar.
						<br></br>
						Si estás de suerte, quizá tengamos unos cuantos PopCakes que te endulzarán todo el día.
						<br></br><br></br>
						¿A qué estás esperando?
						<hr></hr>
						
					</TextoTitulo>
				</div>
			</div>

			<div className="col-12 d-sm-flex justify-content-around pb-5">
				{tarjetas.map((tarjeta) => (
					<Tarjeta tarjeta={tarjeta} key={tarjeta.titulo}></Tarjeta>
				))}
			</div>
		</div>
	);
};

export default TarjetasArticulos;
