import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import './producto.css';
import meme from '../../assets/Local/meme.jpg';
const TextArticulo = styled.p`
	font-family: 'Times-New-Roman';
	font-size: 12px;
`;

const Producto = ({ producto, guardarAlerta }) => {
	const { nombre, url_imagen_principal, precio_bruto, referencia } = producto;

	const [cantidad, guardarCantidad] = useState(0);

	const handleChange = (e) => {
		guardarCantidad(e.target.value);
	};

	const handleClick = (e) => {
		if (cantidad !== 0) {
			guardarCantidad(0);
			guardarAlerta({
				mostrar: true,
				msg: 'Artículo/s añadido/s al carrito',
			});
			let articulos = [];
			let productoExiste = false;
			producto.cantidad = cantidad;
			articulos = JSON.parse(localStorage.getItem('carrito'));
			if (articulos)
				articulos.map((articulo) => {
					if (articulo.referencia === producto.referencia) {
						//Hay que hacer un parse a INT porque sino, me suma los strings
						let cantidadArticuloParseada = parseInt(articulo.cantidad);
						cantidadArticuloParseada += parseInt(producto.cantidad);

						articulo.cantidad = cantidadArticuloParseada;
						localStorage.setItem('carrito', JSON.stringify(articulos));
						productoExiste = true;
						return;
					}
				});
			else {
				articulos = [];
			}
			if (!productoExiste) {
				articulos.push(producto);
				localStorage.setItem('carrito', JSON.stringify(articulos));
			}
		}
	};

	return (
		<>
			<div className="col-12 col-sm-5 col-md-3 justify-content-center p-2 ">
				<div className="product-card">
					<div className="row justify-content-center">

						<img className="col-6 h-100" src={url_imagen_principal} alt={nombre} />
					</div>
					<div className="row p-2">
						<div className="col-12">
							<TextArticulo className="card-title">{nombre}</TextArticulo>
						</div>
						<div className="col-12">
							<h6 className="card-subtitle mb-2 text-muted">N.Ref: {referencia}</h6>
						</div>
						<div className="col-12 justify-content-between align-items-center">
							<div className="price text-success">
								<h5 className="mt-4">{precio_bruto}Eur</h5>
							</div>
							<div className="contenedorBotonesProducto">
								<div className="row justify-content-center">
									<input
										className="form-control col-4"
										placeholder="0"
										name="cantidad"
										type="number"
										value={cantidad}
										onChange={handleChange}
									></input>
								</div>
								<div className="row justify-content-center">
									<button className="btn btn-primary mt-3 col-6" onClick={handleClick}>
										Add to Cart
								</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);



};

export default Producto;
