import React from 'react';
import {Link} from 'react-router-dom';
import '../../Css/Tarjeta.css';
import useTarjetaStyles from './hooks/useTarjetaStyles'

const Tarjeta = ({ tarjeta }) => {

	const{TituloArticulo, TextoArticulo} = useTarjetaStyles();

	return (
		<div className="col-sm-4 col-md-3 col-lg-2 col-12 justify-content-center ">
			<TituloArticulo className="text-center mt-4">{tarjeta.titulo}</TituloArticulo>
			<TextoArticulo className="card-text text-center">{tarjeta.texto}</TextoArticulo>
			<div className="row justify-content-center">
				<Link to={`/${tarjeta.titulo}`} className="productLink">

					<img src={tarjeta.imagen} className="tarjeta" alt="imagen" width="50%" height="100%"></img>
				</Link>
			</div>
		</div>
	);
};

export default Tarjeta;
