 import {
	COMPRAR,
	COMPRAR_ERROR,
	COMPRAR_EXITO,
	GUARDAR_DATOS_FACTURACION,
	GUARDAR_DATOS_FACTURACION_EXITO,
} from '../types';
const initialState = {
	datosFacturacion: {},
	productos: {},
	loading: null,
	error: null,
	precioTotal: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case COMPRAR:
			return {
				...state,
				loading: action.payload,
			};
		case COMPRAR_EXITO:
			return {
				...state,
				productos: action.payload,
				loading: false,
			};

		case COMPRAR_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		case GUARDAR_DATOS_FACTURACION:
			return {
				...state,
			};
		case GUARDAR_DATOS_FACTURACION_EXITO:
			return {
				...state,
				datosFacturacion: action.payload,
			};
		default:
			return state;
	}
}
