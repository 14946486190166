import React from 'react';
import useEspecialidadStyles from './hooks/useEspecialidadStyles.jsx'


const Tarta = ({texto, url, alt}) => {

    const {ContenedorImagen, ImagenProducto, TextoProducto, Card} = useEspecialidadStyles();


    return (
     
        <Card className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="row justify-content-center">
                <div className="col-auto mt-3">
                    <TextoProducto>{texto}</TextoProducto>
                </div>

            </div>
            <div className="row justify-content-center">
                <ContenedorImagen className="col-12 mt-3">
                   <ImagenProducto src={url} alt={alt}  className="rounded imagen"></ImagenProducto>
                </ContenedorImagen>
            </div>
        </Card>
      );
}
 
export default Tarta;