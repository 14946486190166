import styled from '@emotion/styled';

const TituloHeader = styled.h2`
    font-size:.5rem;
    font-weight:600;
    position: relative;
    overflow: hidden;
    
    span {
        display: inline-block;
        vertical-align: baseline;
        zoom: 1;
        *display: inline;
        *vertical-align: auto;
        position: relative;
        padding: 0 20px;

        &:before, &:after {
            content: '';
            display: block;
            width: 90px;
            position: absolute;
            top: 0.73em;
            border-top: 1.5px solid white;
        }

        &:before { right: 100%; }
        &:after { left: 100%; }
    }

    @media(min-width:300px){
        font-size:.8rem;
    }
    @media(min-width:500px){
        font-size:1.5rem;
    }
    @media(min-width:700px){
        font-size:2rem;
    }
    @media(min-width:1000px){
        font-size:2.5rem;
    }    

`

const CajaDescripcionLocal = styled.div`

line-height: 18px;
margin-top: 10px;
padding: 15px;
box-shadow: 0px 1px 2px #b5afaf, 1px 2px 3px #c6c6c6, 2px 3px 4px #888;
border-radius: 5px;
font-size:10px;
background-color:white;

@media(min-width:768px){
    font-size:15px;
}
`
const TituloContenedor= styled.h1`
    font-size:17px;
    font-weight:500;

    @media(min-width:992px){
        font-size:20px;
    }

    @media(min-width:1200px){
        font-size:30px;
    }

`

const useLocalStyles = () => {
    return {TituloHeader, CajaDescripcionLocal, TituloContenedor};
}

export default useLocalStyles;