import React,{useState} from 'react';
import Especialidades from '../components/Especialidades/Especialidades';
const Galletas = () => {
    const [tartas, saveTartas] = useState([
        {
            texto:"Crispy de chocolate",
            url:'https://sevilla.abc.es/gurme/wp-content/uploads/sites/24/2014/04/receta-galletas-caseras.jpg',
            alt:"Crispy de chocolate"   
        },
        {
            texto:"Cereales",
            url:'https://4.bp.blogspot.com/-o4GzgazPIjI/XrWWUprIasI/AAAAAAAALww/mbXXih590R8D8wZItf_Lj7v71EtrktZCgCLcBGAsYHQ/s1600/galletas-avena-facil.JPG',
            alt:"Cereales"
        },
        {
            texto:"Jengibre",
            url:'https://www.recetasderechupete.com/wp-content/uploads/2013/12/Galletas-de-jengibre.jpg',
            alt:"Jengibre"
        },
        {
            texto:"Coco",
            url:'https://cdn7.recetasdeescandalo.com/wp-content/uploads/2019/01/Como-hacer-galletas-de-avena-caseras-y-muy-faciles.-Receta-paso-a-paso.jpg',
            alt:"Coco"
        }
    ])
    return (  
        <Especialidades productos={tartas} titulo="Carta de Galletas"></Especialidades>
    );
}
 
export default Galletas;