import { useEffect } from 'react';
import styled from '@emotion/styled';

const Titulo = styled.h1`
	font-family: 'Times New Roman';
	font-size: .9rem;
	font-weight: bold;
	position: relative;
    overflow: hidden;
    
    span {
        display: inline-block;
        vertical-align: baseline;
        zoom: 1;
        *display: inline;
        *vertical-align: auto;
        position: relative;
        padding: 0 20px;

        &:before, &:after {
            content: '';
            display: block;
            width: 70px;
            position: absolute;
            top: 0.67em;
            border-top: 2px solid white;
        }

        &:before { right: 100%; }
        &:after { left: 100%; }
	}

	@media(min-width:500px){
		font-size:2rem;
	}
	@media(min-width:800px){
		font-size:2.5rem;
	}
	@media(min-width:1000px){
		font-size:3rem;
	}
`;



const useContactoStyles= () => {
	useEffect(() => {
		localStorage.setItem('compra', false);
	}, []);

    return {Titulo}
};

export default useContactoStyles;
