import styled from '@emotion/styled';
const TituloArticulo = styled.h3`
	font-size: 20px;
	margin-bottom: 0px;
	@media (min-width: 900px) {
		font-size: 40px;
	}
`;
const TextoArticulo = styled.p`
	font-size: 14px;
	font-family: none;
	@media (min-width: 900px) {
		font-size: 20px;
	}
`;
const useTarjetaStyles= ()=> {
	return {TituloArticulo, TextoArticulo};	
};

export default useTarjetaStyles;
