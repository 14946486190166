import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

const ContenedorSidebar = styled.div`
	border-radius: 5px;
	min-height: 150px;
`;

const CarritoSidebar = ({ precioTotal, recargarProductos }) => {
	const history = useHistory();

	const handleClick = () => {
		history.push('/compra');
		recargarProductos();
	};

	useEffect(() => {
		localStorage.setItem('precioTotal', precioTotal);
	}, [precioTotal]);
	
	return (
		<ContenedorSidebar className="row bg-dark text-light justify-content-center pt-2 pb-2">
			<div className="col-12 text-center d-flex justify-content-center align-items-center">
				<p>Precio: {precioTotal.toFixed(2)}</p>
			</div>
			{precioTotal > 70 ? null : (
				<>
					<div className="col-12 text-center">
						<p>+9eur gastos envío</p>
					</div>
					<div className="col-12 text-center">
						<p>Precio Final: {(precioTotal + 9).toFixed(2)}</p>
					</div>
				</>
			)}
			<div className="col-12 d-flex justify-content-center align-items-center">
				<button className="btn btn-lg btn-warning text-center" onClick={handleClick}>
					Comprar
				</button>
			</div>
		</ContenedorSidebar>
	);
};

export default CarritoSidebar;
