import React from 'react'
import styled from '@emotion/styled'
const TextoTrabajador = styled.p`
  font-size: 13px;
  @media (min-width: 900px) {
    font-size: 20px;
  }
`
const ContenedorTrabajador = styled.div`
    box-shadow: 0px 1px 2px black, 1px 2px 3px black;
`
const TituloTrabajador = styled.h2`
  font-size:1.5rem;

  @media(min-width:768px){
    font-size:2rem;
  }
  @media(min-width:1200px){
    font-size:2.5rem;
  }
`
const useTrabajadorStyles= () => {
    return {TextoTrabajador, ContenedorTrabajador, TituloTrabajador};
}

export default useTrabajadorStyles; 
