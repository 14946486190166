import React from 'react';
import {Parallax} from 'react-parallax';
import '../Css/Contacto.css'
import useContactoStyles from './hooks/useContactoStyles';
import Form from "../components/ui/FormContacto";
import logo from '../assets/Logo/logo.webp'
import styled from '@emotion/styled'


const Imagen = styled.img`
	height:200px;
	width:200px;
`
const ContenedorImagen = styled.div`
	display:flex;
	justify-content:center;
	align-items:center;

`
const Contacto = () => {

	const {Titulo} = useContactoStyles();

	return (
		<>
			<div className="p-3">
				<div className="row">
					<Parallax bgImage="https://i.ibb.co/Lngp738/contacto.webp" strength={300} className="col-12 p-0 parallax-background">
						<div className="row justify-content-center align-items-center header-background">
							<div className="col-8 text-center text-light">
								
							<Titulo><span>Contacta con nosotros</span></Titulo>
							</div>
						</div>
					</Parallax>
				</div>
				<div className="row justify-content-center">
					<div className="col-12 box-contacto pt-5 pb-5 pl-4 pr-4">
						<div className="row  p-3 box-formulario">
							
							<ContenedorImagen className="col-12 col-lg-6 col-xl-7 order-lg-1">
								<Imagen src={logo}></Imagen>
							</ContenedorImagen>
							<div className="col-12 col-lg-6 col-xl-5 order-lg-0">
								<h3>Gracias por confiar en nosotros</h3>
								<p>Envíanos tus dudas  y responderemos con la mayor brevedad posible.</p>
								<Form></Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contacto;
